import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from '../icons/thinX';
import itemStatus from "../../functions/itemStatus";
import availabilityString from "../../functions/availabilityString";
import { useContext, useState } from "react";
import returnBestImage from "../../functions/returnBestImage";
import postData from "../../functions/postData";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay";
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import configs from "../../functions/configs";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import {UserContext} from "../../";
import IconRobot from "../icons/iconRobot";
import ReactGA from 'react-ga4';
import detectUserAgent from "../../functions/detectUserAgent";
import ToolMessage from "../shared/toolMessage";

function GetThisItemOverlay (props) {

  const [formState, setFormState] = useState({});
  const [overlayTitle, setOverlayTitle] = useState('Get this item');
  
  const conf = configs();
  
  const groupData = {};

  const user = useContext(UserContext);

  const url = new URL(window.location);

  let referringUrl;
  
  try {
    referringUrl = new URL(document.referrer);
  } catch {}
  

  try {
    if (user.data.session.bpl_catalog.ptype === 8) {
      user.data.session.bpl_catalog.home_library = '90';
    }
  } catch {}

  const selectedBib = {};

  let title = '';
  let author = '';
  let image = '';

  try {
  
    if (document.querySelector('.App').classList.contains('item-page')) {

      groupData.docs = props.solrData.response.docs;
      
    } else {
      
      if (typeof props.solrData.grouped !== 'undefined') {
        props.solrData.grouped.ss_grouping.groups.forEach((group) => {
          group.doclist.docs.forEach((bib) => {
            if (bib.id === props.selectedBibId) {
              groupData.docs = group.doclist.docs;
            }
          });
        });
      } else {
        props.solrData.response.docs.forEach((doc)=> {
          if (props.selectedBibId === doc.id) {
            groupData.docs = [doc];
          }
        });
      }

    }

    try {
      title = groupData.docs[0].title;
      author = groupData.docs[0].author;
      image = returnBestImage(groupData.docs[0]);
    } catch {}
    

    groupData.docs.forEach((bib) => {
      if (bib.id === formState.bibId) {
        image = returnBestImage(bib);
        selectedBib.data = bib;
      }
    });

  } catch {}  

  const FormatSelectorOverlay = (props) => {

    const updateForm = (e) => {
      e.preventDefault();
      props.data.forEach(bib => {
        if (bib.id === e.currentTarget.attributes.bib.value) {
          if (bib.eprovider === 'OverDrive, Inc.') {
            if (bib.available) {
              setFormState({type: 'overdriveCheckout', bibId: bib.id});
              setOverlayTitle('Check out');
            } else {
              setFormState({type: 'overdriveHold', bibId: bib.id});
              setOverlayTitle('Place a hold');
            }
          } else if (bib.eprovider === 'Online Resource') {
            setFormState({type: 'linkAccess', bibId: bib.id, url: bib.eurl});
            setOverlayTitle('Online access');
          } else if (bib.material_type === "Music LP") {
            setFormState({type: 'musicLP', bibId: bib.id});
            setOverlayTitle('Checkout in person');
          } else if (bib.call_number === 'CAMERA') {
            setFormState({type: 'camera', bibId: bib.id});
            setOverlayTitle('Place a hold');
          } else if (bib.call_number === 'SEWING MACHINE') {
            setFormState({type: 'sewingMachine', bibId: bib.id});
            setOverlayTitle('Place a hold');
          } else if (bib.material_type === "Archival Collection") {
            setOverlayTitle('Request access');
          } else if (bib.title.indexOf('[BOOK DISCUSSION]') > -1) {
            setFormState({type: 'multipleSierraItemHolds', bibId: bib.id});
            setOverlayTitle('Place a hold');
          } else {
            setFormState({type: 'sierraHold', bibId: bib.id});
            setOverlayTitle('Place a hold');
          }
        }
      });
    }

    const Format = (props) => {

      let linkClasses = 'individual-format';
      let format = '';
      let year = '';
      let status = '';
      let availability = '';

      try {
        if (props.data.material_type) {
          format = props.data.material_type;
        }
        if (props.data.publishYear) {
          year = ' - ' + props.data.publishYear;
        }
        status = itemStatus(props.data);
        availability = availabilityString(props.data);
      } catch {}

      let statusClasses = "status";
      if (status === 'Available Now') {
        statusClasses = "status color-available";
      } else if (status === 'Always Available') {
        statusClasses = "status color-available";
      } else if (status === 'Place a hold') {
        statusClasses = "status color-available";
      } else if (status === 'Wait List') {
        statusClasses = "status color-pending";
      } else if (status === 'Library Use Only') {
        statusClasses = "status color-pending";
      } else if (status === 'On Order') {
        statusClasses = "status color-pending";
      }

      if (props.data.id === formState.bibId) {
        linkClasses += ' format-selected';
      }

      if (props.data.material_type === 'Archival Collection') {
        statusClasses = "status color-available";
        status = "Available Now";
        availability = 'Access available on request';
      }

      return (
        <a onClick={updateForm} bib={props.data.id} oid={props.data.econtrolnumber} aria-label="select-this-format" href="#" className={linkClasses}>
          <div className="bold">{format} {year}</div>
          <div className={statusClasses}>{status}</div>
          <div className="availability">{availability}</div>
        </a>
      )
    }

    const FormatsMap = (props) => {
      try {
        return (props.data.map((item) => <Format key={item.id} data={item} ></Format>));
      } catch {}
    }

    try {
      return (
        <div className="formats-container">
          <div className="formats-header bold">Formats</div>
          <div className="format-selection-container">
            <FormatsMap data={props.data}></FormatsMap>
          </div>
        </div>
      )
    } catch {}
  }

  const sierraPlaceHold = (e) => {
    e.preventDefault();
    
    let encodedData = 'sierratype=b';
    encodedData += '&recordnumber=' + document.querySelector('#get-this-item-overlay a.individual-format.format-selected').attributes.bib.value;
    encodedData += '&location=' + document.querySelector('#sierra-hold-pickup-location').value;
    encodedData += '&agent=' + detectUserAgent();
    try {
      if (url.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + url.searchParams.get('booklist');
      } else if (referringUrl.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + referringUrl.searchParams.get('booklist');
      }
    } catch {}

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/sierra/sierra-place-hold.php", encodedData).then((res) => {
      console.log(res);
      toggleLoadingOverlay();
      if (res === null) { // success
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchSuccessOverlay(
          "We'll let you know when your hold is ready. Your hold can be viewed and edited in My Account in a few moments.",
          ""
        );
      } else if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // http error
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else { // error from sierra api
        if (typeof res.name !== 'undefined') {
          if (res.name === "Volume record selection is required to proceed.") {
            setFormState({type: 'sierraItemHold', bibId: selectedBib.data.id, volumes: res.details.itemsAsVolumes});
          } else {
            document.querySelector("#get-this-item-overlay .close-overlay").click();
            launchErrorOverlay(res.description);
          }
        } else {
          document.querySelector("#get-this-item-overlay .close-overlay").click();
          launchErrorOverlay(res.description);
        }
      }
    });

  }

  const sierraPlaceItemHold = (e) => {
    e.preventDefault();
    
    let encodedData = 'sierratype=i';
    encodedData += '&recordnumber=' + document.querySelector('#get-this-item-overlay input[name="volume"]:checked').attributes.id.value;
    encodedData += '&location=' + document.querySelector('#sierra-hold-pickup-location').value;
    encodedData += '&agent=' + detectUserAgent();
    try {
      if (url.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + url.searchParams.get('booklist');
      } else if (referringUrl.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + referringUrl.searchParams.get('booklist');
      }
    } catch {}

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/sierra/sierra-place-hold.php", encodedData).then((res) => {
      toggleLoadingOverlay();
      if (res === null) { // success
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchSuccessOverlay(
          "Your hold can be viewed and edited in My Account in a few momements.",
          "<a href='/my-account'>My Account</a>"
        );
      } else if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // http error
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else { // error from sierra api
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay(res.description);
      }
    });

  }

  const sierraPlaceMultipleItemHold = (e) => {
    e.preventDefault();
    
    let checkboxElements = document.querySelectorAll('.multiple-sierra-item-copy-holds-container input:checked');
    let recordNumbers = '';

    checkboxElements.forEach(element => {
      recordNumbers += element.id.replace('item-', '') + ',';
    })

    recordNumbers = recordNumbers.replace(/\,$/, '');

    if (recordNumbers === '') {
      return ''
    }
    
    let encodedData = '&recordnumbers=' + recordNumbers;
    encodedData += '&location=' + document.querySelector('#sierra-hold-pickup-location').value;
    encodedData += '&agent=' + detectUserAgent();

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/sierra/sierra-place-multiple-item-holds.php", encodedData).then((res) => {
      toggleLoadingOverlay();
      console.log(res);
      if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // http error
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else {
        try {
          let errorCount = 0;
          for (let iterator = 0; iterator < res.length; iterator++) {
            if (res[iterator].response !== null) {
              errorCount++;
            }
          }

          if (errorCount === 0) {
            document.querySelector("#get-this-item-overlay .close-overlay").click();
            launchSuccessOverlay(
              "Your hold(s) can be viewed and edited in My Account in a few momements. Enjoy your book club!",
              "<a href='/my-account'>My Account</a>"
            );
          } else {
            let successCount = checkboxElements.length - errorCount;
            document.querySelector("#get-this-item-overlay .close-overlay").click();
            launchErrorOverlay(errorCount + ' hold(s) could not be completed. ' + successCount + ' hold(s) completed successfully.', '');
          }
          
        } catch {
          document.querySelector("#get-this-item-overlay .close-overlay").click();
            launchSuccessOverlay(
              "Your hold(s) can be viewed and edited in My Account in a few momements. Enjoy your book club!",
              "<a href='/my-account'>My Account</a>"
            );
        }
      }
    });

  }

  const overdriveCheckout = (e) => {
    e.preventDefault();
    let encodedData = 'oid=' + document.querySelector('#get-this-item-overlay a.individual-format.format-selected').attributes.oid.value;
    encodedData += '&agent=' + detectUserAgent();
    try {
      if (url.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + url.searchParams.get('booklist');
      } else if (referringUrl.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + referringUrl.searchParams.get('booklist');
      }
    } catch {}

    toggleLoadingOverlay();

    postData(conf.baseAPI + "/overdrive/overdrive-checkout.php", encodedData).then((res) => {
      toggleLoadingOverlay();
      if (typeof res.reserveId !== 'undefined') { // success
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchSuccessOverlay(
          "You can access your checkout in My Account.",
          "<a href='/my-account'>My Account</a>"
        );
      } else if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // network error
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else if (res.errorCode === "NoCopiesAvailable") {
        setFormState({type: 'overdriveHoldApology', bibId: selectedBib.data.id});
        setOverlayTitle('Place a hold');
        setTimeout(()=>{
          try {
            document.querySelector('#overdrive-hold-email').focus({ focusVisible: true });
          } catch {}
        },20);
      } else { 
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay(res.message);
      }
    });
    
  }

  const overdrivePlaceHold = (e) => {
    e.preventDefault();
    let encodedData = 'oid=' + document.querySelector('#get-this-item-overlay a.individual-format.format-selected').attributes.oid.value;
    encodedData += '&email=' + encodeURIComponent(document.querySelector('#overdrive-hold-email').value.trim());
    encodedData += '&agent=' + detectUserAgent();
    try {
      if (url.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + url.searchParams.get('booklist');
      } else if (referringUrl.searchParams.get('booklist') !== null) {
        encodedData += '&booklist=' + referringUrl.searchParams.get('booklist');
      }
    } catch {}
    
    toggleLoadingOverlay();

    postData(conf.baseAPI + "/overdrive/overdrive-hold.php", encodedData).then((res) => {
      toggleLoadingOverlay();
      if (typeof res.reserveId !== 'undefined') { // success
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchSuccessOverlay(
          "You can manage your hold in My Account.",
          "<a href='/my-account'>My Account</a>"
        );
      } else if (typeof res.sessionExpired !== 'undefined') {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        toggleOverlay('#login-overlay', e);
      } else if (typeof res.error !== 'undefined') { // network error
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay('Sorry, a network error occurred. Check your connection and try again. If this issue persists, contact us.');
      } else {
        document.querySelector("#get-this-item-overlay .close-overlay").click();
        launchErrorOverlay(res.message);
      }
    });
  }

  const PickupLocationOptions = () => {

    try {

      const PickupLocation = (props) => {
        return (
          <option value={props.data.code}>{props.data.name}</option>
        )
      }

      try {
        if (user.data.session.bpl_catalog.ptype === 8) {
          return conf.soaPickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)
        }
      } catch {}
  
      if (selectedBib.data.material_type === 'Board Game') {
        return conf.boardGamePickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)
      }
  
      if (selectedBib.data.material_type === 'Musical Instrument') {
        return conf.musicalInstrumentPickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)
      }
  
      if (selectedBib.data.call_number === 'TELESCOPE') {
        return conf.telescopePickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)
      }

      if (selectedBib.data.call_number === 'TOOL') {
        return conf.toolLibraryPickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)
      }
  
      return conf.defaultPickupLocations.map((item)=><PickupLocation key={item.code} data={item}></PickupLocation>)

    } catch {}
    
  }

  const GetThisItemForm = (props) => {

    try {
      /*
      let telescopeMessageStyle = {color: 'darkRed', marginTop: '10px'};
      if (groupData.docs[0].call_number === 'TELESCOPE') {
        return (
          <div id="telescope-message" style={telescopeMessageStyle}>Due to high demand, holds on telescopes have been temporarily disabled.</div>
        )
      }
        */
    } catch {}

    try {
      
      if (groupData.docs[0].material_type === 'Archival Collection') {
        return (
          <div className="margin-top-twenty">This collection is available on site at the <a target="_blank" href="https://www.bklynlibrary.org/center-for-brooklyn-history">Center for Brooklyn History</a>.<br></br><br></br> Email us at <a href="mailto:cbhreference@bklynlibrary.org">cbhreference@bklynlibrary.org</a> to request access.</div>
        )
      }
    } catch {}

    try {
      if (url.searchParams.get('booklist') !== null) {
        if (typeof selectedBib.data !== 'undefined') {
          if (typeof selectedBib.data.id !== 'undefined') {
            console.log('selectedBib data', document.querySelector('.booklist-title').textContent + ' | ' + selectedBib.data.title);
            ReactGA.event({category:'hold_placed_from_list', action: 'Hold Placed From List', label: document.querySelector('.booklist-title').textContent + ' | ' + selectedBib.data.title});
          }
        }
      }
    } catch {}
    

    if (formState.type === 'sierraHold') {
      let homeLibrary = '01';
      try {
        homeLibrary = user.data.session.bpl_catalog.home_library;
      } catch {}

      const ZineLibraryMessage = () => {
        return ''; // no longer needed
        try {
          if (groupData.docs[0].call_number.indexOf('BZB ') > -1) {
            return (
              <div>Thank you for placing a hold for items in the BPL Zine Box! The project has been put on a brief pause and all items will be sent out on September 13, 2024. <br></br><br></br></div>
            )
          }
        } catch {}
      }

      const SierraHoldLocationMessage = (props) => {
        try {
          if (props.data.material_type !== 'Tool') {
            return (
              <div className="sierra-hold-location-message">If your local branch isn't included as a pickup location, it may be closed. Check our <a target="_blank" href="https://www.bklynlibrary.org/locations">location map</a> for more information.</div>
            )
          }
        } catch {}
      }

      const BoardGameMessage = (props) => {
        try {
          if (props.data.material_type === 'Board Game') {
            return (
              <div className="sierra-board-game-message">Please note - only 3 board games can be checked out at one time.</div>
            )
          }
        } catch {}
      }


      // <ToolMessage data={selectedBib.data} /> 

      try {
        if (groupData.docs[0].call_number.indexOf('BZB ') > -1) {
          return (
            <div>The BPL Zine Box project is on hold to accommodate restructuring of the project. If you would like to check out items contact Emma Eriksson directly <a href="mailto:eeriksson@bklynlibrary.org">eeriksson@bklynlibrary.org</a></div>
          )
        }
      } catch {}

      return (
        <div>
          <form onSubmit={sierraPlaceHold} id="sierra-place-hold-form">
          <ZineLibraryMessage />
          <BoardGameMessage data={selectedBib.data} />
            <div>
              <label htmlFor="sierra-hold-pickup-location">Pickup location</label>
              <select defaultValue={homeLibrary} id="sierra-hold-pickup-location">
                <PickupLocationOptions></PickupLocationOptions>
            </select>
            </div>
            <button type="submit" value={formState.bibId}>Place a hold</button>
            <SierraHoldLocationMessage data={selectedBib.data} />
          </form>
        </div>
      )
    } else if (formState.type === 'sierraItemHold') {
      let homeLibrary = '01';
      try {
        homeLibrary = user.data.session.bpl_catalog.home_library;
      } catch {}

      const VolumeOption = (props) => {

        let labelText = '';

        try {
          labelText += props.data.callNumber + " " + props.data.location.name;
          if (typeof props.data.status.duedate !== 'undefined') {
            labelText += ' - Wait List';
          } else {
            labelText += ' - Available';
          }
        } catch {}

        return (
          <div className="volume-option-container">
            <input name="volume" type="radio" id={props.data.id}></input>
            <label for={props.data.id}>{labelText}</label>
          </div>
        )
      }

      const VolumeOptions = (props) => {
        return formState.volumes.map(item => <VolumeOption key={item.id} data={item}></VolumeOption>)
      }

      return (
        <div>
          <form onSubmit={sierraPlaceItemHold} id="sierra-place-hold-form">
            <p>Please select a volume/edition for this hold</p>
            <VolumeOptions></VolumeOptions>
            <br></br>
            <div>
              <label htmlFor="sierra-hold-pickup-location">Pickup location</label>
              <select defaultValue={homeLibrary} id="sierra-hold-pickup-location">
                <PickupLocationOptions></PickupLocationOptions>
            </select>
            </div>
            <button type="submit" value={formState.bibId}>Place a hold</button>
          </form>
        </div>
      )
    } else if (formState.type === 'multipleSierraItemHolds') { // for staff Book Discussion holds

      let homeLibrary = '01';
      try {
        homeLibrary = user.data.session.bpl_catalog.home_library;
      } catch {}

      const MultipleItemCopyOptions = (props) => {

        const ItemCopyOption = (props) => {
          try {

            let item = JSON.parse(props.data);
            let status = item.status.display;
            let identifier = 'item-' + item.id;
            if (typeof item.status.duedate !== 'undefined') {
              status = 'DUE ' + item.status.duedate.replace(/T.+/, '');
            }
            let displayText = item.location.name + ' - ' + status;

            if (displayText.indexOf('CHECK SHELVES') > -1 ||
                displayText.indexOf('DUE') > -1 ||
                displayText.indexOf('IN TRANSIT') > -1) 
                {
                  return (
                    <div className="multiple-sierra-item-copy-holds-container">
                      <input type="checkbox" id={identifier}/>
                      <label htmlFor={identifier}>{displayText}</label>
                    </div>
                  )
            }
          } catch {}
        }
        try {
          return groupData.docs[0].sm_item_data.map(item => <ItemCopyOption key={item} data={item} />)
        } catch {}
      }
      return (
        <div>
          <form onSubmit={sierraPlaceMultipleItemHold} id="sierra-place-hold-form">
            <p><strong>Select one or more copies</strong></p>
            <MultipleItemCopyOptions />
            <br></br>
            <div>
              <label htmlFor="sierra-hold-pickup-location">Pickup location</label>
              <select defaultValue={homeLibrary} id="sierra-hold-pickup-location">
                <PickupLocationOptions></PickupLocationOptions>
            </select>
            </div>
            <button type="submit" value={formState.bibId}>Place a hold</button>
          </form>
        </div>
      )
    } else if (formState.type === 'overdriveCheckout') {
      return (
        <div>
          <form onSubmit={overdriveCheckout} id="overdrive-checkout-form">
            <div>This digital item is available and can be checked out now.</div>
            <button type="submit" value={formState.bibId}>Check out now</button>
          </form>
        </div>
      )
    } else if (formState.type === 'overdriveHold') {
      let userEmail = '';
      try {
        userEmail = user.data.session.bpl_catalog.email;
      } catch{}
      return (
        <div>
          <form onSubmit={overdrivePlaceHold} id="overdrive-place-hold-form">
            <div>
              <label htmlFor="overdrive-hold-email">Notification email</label>
              <input type="email" defaultValue={userEmail} id="overdrive-hold-email"></input>
            </div>
            <button type="submit" value={formState.bibId}>Place a hold</button>
          </form>
        </div>
      )
    } else if (formState.type === 'overdriveHoldApology') {
      let userEmail = '';
      try {
        userEmail = user.data.session.bpl_catalog.email;
      } catch{}
      return (
        <div>
          <form onSubmit={overdrivePlaceHold} id="overdrive-place-hold-form">
            <div className="overdrive-hold-apology-message flex-small-gap"><div><IconRobot></IconRobot></div><div>Sorry - someone just beat you to this checkout! Would you like to place a hold?</div></div>
            <div>
              <label htmlFor="overdrive-hold-email">Notification email</label>
              <input type="email" defaultValue={userEmail} id="overdrive-hold-email"></input>
            </div>
            <button type="submit" value={formState.bibId}>Place a hold</button>
          </form>
        </div>
      )
    } else if (formState.type === 'linkAccess') {
      return (
        <div>
          <p>This resource is accessed online. <a target="_blank" href={formState.url}>Click here for access</a>.</p>
        </div>
      )
    } else if (formState.type === 'musicLP') {
      return (
        <div>
          <p>LPs cannot be placed on hold. Please visit the <a href="https://www.bklynlibrary.org/locations/central">Central Library</a> to check out these materials.</p>
        </div>
      )
    } else if (formState.type === 'camera') {
      return (
        <div>
          <p>Please <a href="https://forms.office.com/r/BvXBhmTkgb">fill out the Request Form</a> to place a hold on a camera kit. Library staff will place the hold for you.  You will receive an email within six business days to confirm your hold and provide more information about borrowing camera kits from BPL.</p>
        </div>
      )
    } else if (formState.type ==='sewingMachine') {
      return (
        <div>
          <p>Please <a href="https://forms.office.com/r/RVPYsVGs60">fill out this form</a> to request this machine.</p>
        </div>
      )
    }
  }

  const closeOverlay = (e) => {
    toggleOverlay('#get-this-item-overlay', e);
  }

  const containerClick = (e) => {
    if (e.target.classList.contains('overlay-container')) {
        closeOverlay();
    }
  }

  let touchstartY = 0;
  let touchendY = 0;
  const handleTouchStart = (event) => {
      touchstartY = event.touches[0].screenY;
  }
  const handleTouchMove = (event) => {
      touchendY = event.touches[0].screenY;
  }
  const handleTouchEnd = (event) => {
      if (touchendY > (touchstartY + 300)) {
          closeOverlay();
      }
      touchstartY = 0;
      touchendY = 0;
  }
 
  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id="get-this-item-overlay" className='overlay-container'>
      <div className='overlay-body'>
        <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
        <h3>{overlayTitle}</h3>

        <div className="flex-medium-gap">

          <img className="get-this-item-overlay-image" alt="book jacket art" onError={fallbackImageHandler} src={image}></img>

          <div className="full-width">

            <div className="get-this-item-title">
              {title}
            </div>
            <div className="get-this-item-author">
              {author}
            </div>

            <FormatSelectorOverlay data={groupData.docs}></FormatSelectorOverlay>

            <GetThisItemForm></GetThisItemForm>

          </div>

        </div>
      </div>
    </div>
  )
}

export default GetThisItemOverlay;