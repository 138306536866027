import './App.css';
import { useState, useContext } from "react";
import NavBar from "./components/shared/navBar";
import Footer from "./components/shared/footer";
import configs from "./functions/configs";
import GetThisItemButton from "./components/shared/getThisItemButton";
import AddToListButton from "./components/shared/addToListButton";
import ShareItemButton from "./components/shared/shareItemButton";
import FormatSelector from "./components/shared/formatSelector";
import returnBestImage from "./functions/returnBestImage";
import Summary from "./components/itemPage/summary";
import PrimaryItemDetails from "./components/itemPage/primaryItemDetails";
import onLoadImageHandler from "./functions/onLoadImageHandler";
import fallbackImageHandler from "./functions/fallbackImageHandler";
import ItemPageError from "./components/itemPage/itemPageError";
import ItemPageLoading from "./components/itemPage/itemPageLoading";
import useItemFetch from "./hooks/useItemFetch";
import MetadataSection from "./components/itemPage/metadataSection";
import ItemLocationTable from "./components/itemPage/itemLocationTable";
import HoldingsTable from "./components/itemPage/holdingsTable";
import YouMayAlsoLike from "./components/itemPage/youMayAlsoLike";
import BreadCrumb from "./components/shared/breadcrumb";
import OverlayContainer from "./components/overlays/overlayContainer";
import BottomNav from "./components/shared/bottomNav";
import parseVarfields from './functions/parseVarfields';
import OrderInformation from "./components/itemPage/orderInformation";
import RelatedBooklists from "./components/itemPage/relatedBooklists";
import { UserContext } from '.';
import RebalancingClaimButton from './components/shared/rebalancingClaimButton';
import NotOnlineScreen from './components/shared/notOnlineScreen';
import CatalogFormatIcon from './components/icons/catalogFormatIcon';
import NoInternetMessage from './components/shared/noInternetMessage';
import ToolMessage from './components/shared/toolMessage';

let popstateFired = false;

function ItemApp() {

  const conf = configs();

  const user = useContext(UserContext);

  let isUsingStaffBranchCard = false;

  try {
    if (user.data.session.bpl_catalog.ptype === 24) {
      isUsingStaffBranchCard = true;
    }
  } catch {}

  const url = new URL(window.location);

  const bibId = url.searchParams.get('b');

  const [pageData, setPageData] = useState({currentURL: url});
  const [selectedBibId, setSelectedBibId] = useState('');
  const [loginState, setLoginState] = useState('');

  const bibAndGroupData = useItemFetch(conf.selectAPI + '?fq=id%3A' + bibId, isUsingStaffBranchCard);

  const isOnline = window.navigator.onLine;

  const bibData = bibAndGroupData.bib;

  const groupData = bibAndGroupData.group;

  console.log(groupData);

  window.addEventListener('popstate', function(event) {//browser back or forward button click force reload  
    if (!popstateFired) {
      popstateFired = true;
      this.location.reload();
    } else {
      event.preventDefault();
    }
  });

  const updateItemPage = (e) => {
    e.preventDefault();
    url.searchParams.set('b', e.currentTarget.attributes[0].value);
    window.history.pushState({update: url.search}, '', url);
    setPageData(url);
  }

  let title = '';
  let author = '';
  let suppressed = false;
  let imgURL = '';
  let summary = '';
  let authorLink = '';


  try {
    title = bibData.response.docs[0].title;
    if (bibData.response.docs[0].author) {
      author = bibData.response.docs[0].author;
    }
    imgURL = returnBestImage(bibData.response.docs[0]);
    if (title !== '') {
      document.title = title + ' - Brooklyn Public Library';
    }
    try {
      if (bibData.response.docs[0].summary[0] !== '') {
        summary = bibData.response.docs[0].summary[0];
      }
    } catch{}

    try {
      suppressed = bibData.response.docs[0].suppressed;
    } catch {}
    
  } catch {}

  let transliteratedTitle = '';
  let transliteratedAuthor = '';
  let transliteratedSummary = '';

  try {
    
    if (typeof bibData.response.docs[0].sm_bib_varfields !== 'undefined') {
      const varFields = parseVarfields(bibData.response.docs[0].sm_bib_varfields);
      for (let i = 0; i < varFields.length; i++) {
        if (varFields[i].tag === '880') {
          let isAuthor = false;
          let isTitle = false;
          let isSummary = false;

          for (let x = 0; x < varFields[i].subfields.length; x++) {
            if (varFields[i].subfields[x].subfieldTag === '6') {
              if (varFields[i].subfields[x].subfieldContent.match(/^100.+/i)) {
                isAuthor = true;
              }
              if (varFields[i].subfields[x].subfieldContent.match(/^245.+/i)) {
                isTitle = true;
              }
              if (varFields[i].subfields[x].subfieldContent.match(/^520.+/i)) {
                isSummary = true;
              }
            }
          }

          if (isAuthor) {
            for (let x = 0; x < varFields[i].subfields.length; x++) {
              if (varFields[i].subfields[x].subfieldTag === 'a') {
                transliteratedAuthor = varFields[i].subfields[x].subfieldContent;
              }
            }
          }

          if (isTitle) {
            for (let x = 0; x < varFields[i].subfields.length; x++) {
              if (varFields[i].subfields[x].subfieldTag !== '6') {
                transliteratedTitle += varFields[i].subfields[x].subfieldContent + ' ';
              }
            }
            transliteratedTitle = transliteratedTitle.trim();
          }

          if (isSummary) {
            for (let x = 0; x < varFields[i].subfields.length; x++) {
              if (varFields[i].subfields[x].subfieldTag === 'a') {
                transliteratedSummary = varFields[i].subfields[x].subfieldContent;
              }
            }
          }

        }
      }
    }
    
  } catch (err) {console.log(err)}

  if (transliteratedTitle !== '') {
    title += ' ' + transliteratedTitle;
  }

  try {
    authorLink = '/?catalog=true&author=' + encodeURIComponent(author);
  } catch {}

  if (!isUsingStaffBranchCard) {
    if (title === '' || suppressed) {
      if (bibData) {
        return (
          <ItemPageError></ItemPageError>
        );
      } else {
        return (
          <ItemPageLoading></ItemPageLoading>
        );
      }
    } 
  } else {
    
    if (bibData) {
      /*
      return (
        <ItemPageError></ItemPageError>
      )
      */
    } else {
      return (
        <ItemPageLoading></ItemPageLoading>
      );
    }
  }
  
  let sampleLink = '';
  try {
    if (bibData.response.docs[0].esampleurl) {
      sampleLink = bibData.response.docs[0].esampleurl;
    }
  } catch {}

  const Sample = (props) => {
    if (props.text === '') {
      return '';
    }
    return (
      <div className='sample-link-container'><a target="_blank" className="main-color bold sample-link" href={props.text}><CatalogFormatIcon format={bibData.response.docs[0].material_type}/> Sample</a></div>
    )
  }

  const ArchivesSpaceInfo = (props) => {
    try {
      
      const ContentNotes = (props) => {

        let collectionContents = '';

        try {
          for (let i = 0; i < props.data.length; i++) {
            collectionContents += props.data[i].replace(/[\n]/g, '<br>') + '<br><br>';
          }
  
          if (collectionContents === '') {
            return '';
          }
  
          const markup = {__html: collectionContents}
  
          return (
            <div>
              <h3>Collection Contents</h3>
              <div dangerouslySetInnerHTML={markup}></div>
            </div>
          )
        } catch {}
        
      }

      const SubjectsPill = (props) => {
        let link = '';
        try {
          link = '/?catalog=true&subjects=' + encodeURIComponent(props.data);
        } catch {}
        return (
          <a href={link} className="subject-pill-btn">{props.data}</a>
        )
      }
      const SubjectsMap = (props) => {
        return props.data.map((item) => <SubjectsPill key={item} data={item}></SubjectsPill>);
      }
      const Tags = (props) => {
        try {
          if (typeof props.data.subjects !== 'undefined') {
            return (
              <div>
                <h3>Tags</h3>
                <div className="subject-pill-btn-container">
                  <SubjectsMap data={props.data.subjects}></SubjectsMap>
                </div>
              </div>
            )
          } else {
            return ''
          }
        } catch {}
        
      }

      const Extent = (props) => {
        try {
          let text = '';
          
          for (let i = 0; i < props.data.sm_extents.length; i++) {
            text += props.data.sm_extents[i] + ' ';
          }

          return (
            <div>
              <h3>Extent</h3>
              {text}
            </div>
          )
        } catch {}
      }

      const LocationInfo = (props) => {
        try {

          let locationText = '';

          if (props.data.ss_physical_location !== '') {
            locationText += props.data.ss_physical_location + '. ';
          }

          if (props.data.ss_location_info !== '') {
            locationText += props.data.ss_location_info;
          }

          if (locationText === '') {
            return '';
          }

          return (
            <div>
              <h3>Location</h3>
              <div>{locationText}</div>
            </div>
          )
        } catch {}
      }

      const CollectionLanguage = (props) => {
        let language = '';
        try {
          language = props.data.language[0];

          if (language !== '' && language !== null) {
            return (
              <div>
                <h3>Collection Language</h3>
                <div>{language}</div>
              </div>
            )
          }

        } catch {}
      }

      const Access = (props) => {
        try {
          if (props.data.ss_usage_notes === '') {
            return ''
          }
          return (
            <div>
              <h3>Access</h3>
              <div>{props.data.ss_usage_notes}</div>
            </div>
          )
        } catch {}
      }

      if (props.data.material_type === 'Archival Collection') {
        return (
          <div>
            <LocationInfo data={props.data}/>
            <Extent data={props.data}/>
            <ContentNotes data={props.data.sm_resource_contents}/>
            <Tags data={props.data} />
            <CollectionLanguage data={props.data} />
            <Access data={props.data}/>
            
          </div>
        )
      }
    } catch {}
  }

  try {

    return (
      <div className="App item-page">
        <NavBar></NavBar>
        <NoInternetMessage />
        <div className='main-app-container'>
          <header className="app-header">
            <BreadCrumb></BreadCrumb>
            <div className='item-page-header-flex-container'>
              <div className='item-page-image-container'>
                <img onLoad={onLoadImageHandler} onError={fallbackImageHandler} alt={title} src={imgURL}></img>
                <Sample text={sampleLink}/>
              </div>
              <div className='item-page-header-text'>
                <h1 className='item-page-title'>{title}</h1>
                <h2 className='item-page-author notranslate'><a href={authorLink}>{author}</a> {transliteratedAuthor}</h2>
                <div className='result-detail-actions wrap'>
                  <GetThisItemButton setSelectedBibId={setSelectedBibId} bib={bibData.response.docs[0].id}></GetThisItemButton>
                  <AddToListButton setSelectedBibId={setSelectedBibId} bib={bibData.response.docs[0].id}></AddToListButton>
                  <RebalancingClaimButton data={groupData.response.docs} />
                  <ShareItemButton bib={bibData.response.docs[0].id} title={bibData.response.docs[0].title} type={"b"}></ShareItemButton>
                </div>
                <FormatSelector updateItemPage={updateItemPage} groupData={groupData}></FormatSelector>
              </div>
            </div>
          </header>
          <section>
            <ToolMessage data={bibData.response.docs[0]} />
            <PrimaryItemDetails data={bibData.response.docs[0]}></PrimaryItemDetails>
            <Summary transliteration={transliteratedSummary} text={summary}></Summary>
            <ArchivesSpaceInfo data={bibData.response.docs[0]} />
            <OrderInformation data={bibData.response.docs[0]} />
            <HoldingsTable data={bibData.response.docs[0]}></HoldingsTable>
            <RelatedBooklists data={bibData.response.docs[0]} />
            <ItemLocationTable isUsingStaffBranchCard={isUsingStaffBranchCard} data={bibData.response.docs[0]}></ItemLocationTable>
            <YouMayAlsoLike data={bibData.response.docs[0]}></YouMayAlsoLike>
            <MetadataSection groupData={groupData} data={bibData.response.docs[0]}></MetadataSection>
          </section>
        </div>
        <Footer></Footer>
        <BottomNav></BottomNav>
        <OverlayContainer setLoginState={setLoginState} selectedBibId={selectedBibId} solrData={groupData}></OverlayContainer>
      </div>
    )

  } catch {}
  
}

export default ItemApp;