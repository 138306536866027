import IconFacebook from "../icons/iconFacebook";
import IconInstagram from "../icons/iconInstagram";
import IconTwitter from "../icons/iconTwitter";
import IconTikTok from "../icons/iconTikTok";
import IconYouTube from "../icons/iconYouTube";
import IconPodcast from "../icons/iconPodcast";
import DuotoneCircleArrowUp from "../icons/duotoneCircleArrowUp";
import scroll from "../../functions/scroll";
import Accordions from "./accordions";

const Footer = (props) => {

  document.body.onscroll = (e) => {
    var button = document.getElementById('scrolltotop');
    const lastPercentage = Math.min(1, (window.innerHeight + window.scrollY) / document.body.offsetHeight)

    if (true) {
      try {
        // console.log(lastPercentage)
        if (window.innerWidth > 768) {
          if (lastPercentage < 0.5 + 0.05) {
            button.classList.add('hide');
          }
          if (lastPercentage > 0.5 + 0.05) {
            button.classList.remove('hide');
          }
        }
        else if (window.innerWidth < 768) {
          if (lastPercentage < 0.3 + 0.05) {
            button.classList.add('hide');
          }
          if (lastPercentage > 0.3 + 0.05) {
            button.classList.remove('hide');
          }
        }

      }

      catch (error) {
        console.log(error);
      }
    }
  }


  return (

    <div className="footer">
      <Accordions></Accordions>

      <div className="footer-columns-container">

        <div className="footer-column">
          <div className="footer-column-label">Books & More</div>
          <a href="https://discover.bklynlibrary.org/">Books, eBooks & Audiobooks</a>
          <a href="https://discover.bklynlibrary.org/?catalog=true&materialtype=Board+Game">Board Games</a>
          <a href="https://discover.bklynlibrary.org/?catalog=true&sort=popularity&materialtype=DVD&pasttwoyears=true">Movies</a>
          <a href="https://culturepass.nyc/">Museum Passes (Culture Pass)</a>
          <a href="https://discover.bklynlibrary.org/?catalog=true&sort=popularity&materialtype=Online+Music%7C%7CSheet+Music%7C%7CMusic+CD%7C%7CMusic+LP">Music & Recordings</a>
          <a href="https://discover.bklynlibrary.org/?catalog=true&sort=popularity&materialtype=Musical+Instrument">Musical Instruments</a>
          <a href="https://www.bklynlibrary.org/blogs">Blogs</a>
          <a href="https://www.bklynlibrary.org/podcasts">Podcasts</a>
        </div>
      
        

        <div className="footer-column">
          <div className="footer-column-label">Events & Classes</div>
          <a href="https://www.bklynlibrary.org/bpl-presents">BPL Presents</a>
          <a href="https://discover.bklynlibrary.org/?event=true">Events by Branch</a>
          <a href="https://discover.bklynlibrary.org/?event=true&eventage=Kids%7C%7CBirth+to+Five+Years">Events for Kids</a>
          <a href="https://discover.bklynlibrary.org/?event=true&eventage=Teens+%26+Young+Adults">Events for Teens</a>
          <a href="https://www.bklynlibrary.org/exhibitions">Exhibitions</a>
          <a href="https://discover.bklynlibrary.org/?event=true">All events</a>
        </div>

        <div className="footer-column">
          <div className="footer-column-label">Research</div>
          <a href="https://www.bklynlibrary.org/center-for-brooklyn-history">Center for Brooklyn History</a>
          <a href="https://www.bklynlibrary.org/online-resources/topic/business-entrepreneurship">Business & Entrepreneurship</a>
          <a href="https://www.bklynlibrary.org/online-resources/topic/genealogy">Geneology</a>
          <a href="https://www.bklynlibrary.org/online-resources/topic/health-wellness">Health & Wellness</a>
          <a href="https://www.bklynlibrary.org/online-resources/list">All Online Resources & Databases</a>
        </div>

        <div className="footer-column">
          <div className="footer-column-label">Services</div>
          <a href="https://www.bklynlibrary.org/adult-learning">HSE (High School Equivalency) Diploma</a>
          <a href="https://www.bklynlibrary.org/adult-learning/bard-microcollege">College Degree</a>
          <a href="https://www.bklynlibrary.org/business">Career & Business Help</a>
          <a href="https://www.bklynlibrary.org/bal">Ask a Librarian</a>
          <a href="https://www.bklynlibrary.org/bookmatch">BookMatch</a>
          <a href="https://www.bklynlibrary.org/idnyc">ID NYC</a>
          <a href="https://www.bklynlibrary.org/passport">Passport Office</a>
          <a href="https://www.bklynlibrary.org/use-the-library/laptop-loan">Laptops</a>
          <a href="https://www.bklynlibrary.org/use-the-library/print">Printing</a>
          <a href="https://www.bklynlibrary.org/rooms">Room Reservations</a>
        </div>

        <div className="footer-column">
          <div className="footer-column-label">Support Your Library</div>
          <a href="https://donate.bklynlibrary.org/give/387995/#!/donation/checkout?c_src=website&c_src2=footer">Donate</a>
          <a href="https://www.bklynlibrary.org/volunteer">Volunteer</a>
          <a href="https://www.bklynlibrary.org/standup">Advocacy</a>
          <a href="https://www.bklynlibrary.org/support/corporate-sponsors">Sponsorship</a>
          <a href="https://www.bklynlibrary.org/rentals">Private Event Rentals</a>
          <a href="https://shop.bklynlibrary.org/">The Shop at BPL</a>
        </div>

      </div>

      <div className="horizontal-links-container">
        
        <a href="https://bklynlibrary.org" className="footer-logo-link">
          BPL Homepage
        </a>

        <div className="footer-social-icons mobile">
          <a href="http://www.facebook.com/brooklynpubliclibrary" target="_blank" title="Facebook"><IconFacebook></IconFacebook><span className="sr-only">Facebook</span></a>
          <a href="http://www.instagram.com/bklynlibrary" target="_blank" title="Instagram"><IconInstagram></IconInstagram><span className="sr-only">Instagram</span></a>
          <a href="http://www.twitter.com/bklynlibrary" target="_blank" title="Twitter"><IconTwitter></IconTwitter><span className="sr-only">Twitter</span></a>
          <a href="https://www.tiktok.com/@bklynlibrary" target="_blank" title="TikTok"><IconTikTok></IconTikTok><span className="sr-only">TikTok</span></a>
          <a href="https://www.youtube.com/user/BPLvideos" target="_blank" title="YouTube"><IconYouTube></IconYouTube><span className="sr-only">YouTube</span></a>
          <a href="https://www.bklynlibrary.org/podcasts/" target="_blank" title="Podcasts"><IconPodcast></IconPodcast><span className="sr-only">Podcasts</span></a>
        </div>
        <div className="footer-extra-links">
          <a href="https://www.bklynlibrary.org/about">About</a>
          <a href="https://www.bklynlibrary.org/use-the-library/">How to Use the Library</a>
          <a href="https://www.bklynlibrary.org/about/media">News & Media</a>
          <a href="https://www.bklynlibrary.org/use-the-library/policy">Library Policies</a>
          <a href="https://www.bklynlibrary.org/contact">Contact Us</a>
          <a href="https://www.bklynlibrary.org/about/careers">Careers</a>
          <a href="https://www.bklynlibrary.org/accessible-technology">Accessibility</a>
        </div>
      </div>

      <div className="social-media-copywright-container">
        <div className="footer-social-icons">
          <a href="http://www.facebook.com/brooklynpubliclibrary" target="_blank" title="Facebook"><IconFacebook></IconFacebook><span className="sr-only">Facebook</span></a>
          <a href="http://www.instagram.com/bklynlibrary" target="_blank" title="Instagram"><IconInstagram></IconInstagram><span className="sr-only">Instagram</span></a>
          <a href="http://www.twitter.com/bklynlibrary" target="_blank" title="Twitter"><IconTwitter></IconTwitter><span className="sr-only">Twitter</span></a>
          <a href="https://www.tiktok.com/@bklynlibrary" target="_blank" title="TikTok"><IconTikTok></IconTikTok><span className="sr-only">TikTok</span></a>
          <a href="https://www.youtube.com/user/BPLvideos" target="_blank" title="YouTube"><IconYouTube></IconYouTube><span className="sr-only">YouTube</span></a>
          <a href="https://www.bklynlibrary.org/podcasts/" target="_blank" title="Podcasts"><IconPodcast></IconPodcast><span className="sr-only">Podcasts</span></a>
        </div>
        <div className="footer-copywright-text">
          © 2024 Brooklyn Public Library
          <button className="scrolltotop hide" id="scrolltotop" onClick={scroll}><DuotoneCircleArrowUp></DuotoneCircleArrowUp><span className="sr-only">Back to Top Button</span></button>
        </div>
      </div>

    </div>
  )
};

export default Footer;