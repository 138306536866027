import GetThisItemOverlay from './getThisItemOverlay';
import LoginOverlay from './loginOverlay';
import LoadingOverlay from './loadingOverlay';
import SuccessOverlay from './successOverlay';
import ErrorOverlay from './errorOverlay';
import SelfCheckoutOverlay from './selfCheckoutOverlay';
import DigitalCardOverlay from './digitalCardOverlay';
import AccessOverlay from './accessOverlay';
import SmallLoadingOverlay from './smallLoadingOverlay';
import AddToListOverlay from "./addToListOverlay";
import CreateNewListOverlay from "./createNewListOverlay";
import EditListOverlay from './editListOverlay';
import DeleteListOverlay from './deleteListOverlay';
import EditListItemsOverlay from './editListItemsOverlay';
import UpdatePinOverlay from './updatePinOverlay';
import EditNamesOverlay from "./editNamesOverlay";
import EditContactInfoOverlay from './editContactInfoOverlay';
import EditAddressOverlay from './editAddressOverlay';
import RebalancingClaimOverlay from './rebalancingClaimOverlay';
import HistoryOptOutOverlay from './historyOptOutOverlay';
import AccountSideDrawer from './accountSideDrawer';
import AddAccountSideDrawer from './addAccountSideDrawer';
import SwitchAccountSideDrawer from './switchAccountSideDrawer';
import DeleteItemFromHistoryOverlay from './deleteItemFromHistoryOverlay';

const OverlayContainer = (props) => {

    return (
        <div className='overlays-container'>
            <GetThisItemOverlay selectedBibId={props.selectedBibId} solrData={props.solrData} />
            <AddToListOverlay selectedBibId={props.selectedBibId} solrData={props.solrData} />
            <CreateNewListOverlay></CreateNewListOverlay>
            <EditListOverlay></EditListOverlay>
            <EditListItemsOverlay></EditListItemsOverlay>
            <DigitalCardOverlay></DigitalCardOverlay>
            <DeleteListOverlay></DeleteListOverlay>
            <UpdatePinOverlay />
            <EditNamesOverlay />
            <EditContactInfoOverlay />
            <EditAddressOverlay />
            <RebalancingClaimOverlay />
            <LoginOverlay setLoginState={props.setLoginState}></LoginOverlay>
            <SelfCheckoutOverlay></SelfCheckoutOverlay>
            <HistoryOptOutOverlay />
            <DeleteItemFromHistoryOverlay />
            <AccessOverlay></AccessOverlay>
            <AccountSideDrawer />
            <AddAccountSideDrawer setLoginState={props.setLoginState}/>
            <SwitchAccountSideDrawer setLoginState={props.setLoginState}/>
            <SuccessOverlay></SuccessOverlay>
            <ErrorOverlay></ErrorOverlay>
            <LoadingOverlay></LoadingOverlay>
            <SmallLoadingOverlay></SmallLoadingOverlay>
        </div>
    )
    
}

export default OverlayContainer