import toggleOverlay from "../../functions/toggleOverlay";
import ThinX from "../icons/thinX";
import postData from "../../functions/postData";
import configs from "../../functions/configs";
import toggleLoadingOverlay from "../../functions/toggleLoadingOverlay"
import launchSuccessOverlay from "../../functions/launchSuccessOverlay";
import launchErrorOverlay from "../../functions/launchErrorOverlay";
import setCookie from "../../functions/setCookie";

const DeleteItemFromHistoryOverlay = (props) => {

    const conf = configs();

    const overlayId = 'delete-item-from-history-overlay';

    const closeOverlay = (e) => {
        toggleOverlay('#' + overlayId, e);
    }

    const containerClick = (e) => {
        if (e.target.classList.contains('overlay-container')) {
            closeOverlay();
        }
    }

    let touchstartY = 0;
    let touchendY = 0;
    const handleTouchStart = (event) => {
        touchstartY = event.touches[0].screenY;
    }
    const handleTouchMove = (event) => {
        touchendY = event.touches[0].screenY;
    }
    const handleTouchEnd = (event) => {
        if (touchendY > (touchstartY + 300)) {
            closeOverlay();
        }
        touchstartY = 0;
        touchendY = 0;
    }

    const deleteItemFromHistory = (e) => {
        toggleLoadingOverlay();
        const targetHistoryId = e.currentTarget.attributes.identifier.value;
        postData(conf.baseAPI + "/user/delete-item-from-history.php", 'historyid=' + targetHistoryId).then((res) => {
            console.log(res);
            toggleLoadingOverlay();
            try {
                closeOverlay();
                if (typeof res.historyid !== 'undefined') {
                    launchSuccessOverlay('', '');
                    const targetedItem = document.querySelector('button.history-delete-item-button[historyid="'+targetHistoryId+'"]');
                    const elementToHide = targetedItem.closest('.my-account-catalog-record');
                    elementToHide.classList.add('hide');
                    try {
                        let currentCount = document.querySelector('#count-of-history-items').textContent;
                        let currentCountInt = parseInt(currentCount);
                        currentCountInt--;
                        document.querySelector('#count-of-history-items').innerHTML = currentCountInt.toLocaleString();
                        setCookie('bplswitchedhistory', Date.now());
                    } catch {}
                    
                    
                } else {
                    launchErrorOverlay('Sorry, something went wrong');
                }
                
            } catch {}
        });
    }

   
    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onClick={containerClick} id={overlayId} className='overlay-container'>
            <div className='overlay-body'>
            <button aria-label="Close dialog" onClick={closeOverlay} className="close-overlay"><ThinX width='20'></ThinX></button>
                <h3>Delete item from history</h3>
                <p>This action is permanent and cannot be undone.</p>

                <br />
                <br />
                
                <button onClick={deleteItemFromHistory} identifier="" className="default-blue-button confirm-item-deletion">Yes, delete item from history</button>
            </div>
        </div>
    )
}

export default DeleteItemFromHistoryOverlay;