import IconSparkles from "../icons/iconSparkles";

const PrimaryItemDetails = (props) => {
  try {
    let callNumber = '';
    let publisher = '';
    let language = '';
    let targetAge = '';
    let previewLink = '';
    
    
    try {
      if (props.data.call_number) {
        callNumber = '' + props.data.call_number;
      }
      if (props.data.publisher) {
        publisher = props.data.publisher;
      }
      if (props.data.language[0]) {
        language = props.data.language[0];
      }
      if (props.data.target_age[0]) {
        targetAge = props.data.target_age[0];
      }
      if (props.data.esampleurl) {
        previewLink = props.data.esampleurl;
      }
    } catch {}

    const CallNumber = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold notranslate">Call Number: </span><span>{props.text}</span></div>
      )
    }

    const Publisher = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Publisher: </span><span>{props.text}</span></div>
      )
    }

    const Language = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Language: </span><span>{props.text}</span></div>
      )
    }

    const TargetAge = (props) => {
      if (props.text === '') {
        return '';
      }
      return (
        <div><span className="main-color bold">Target Age: </span><span>{props.text}</span></div>
      )
    }

    const ArchivesSpaceLink = (props) => {
      if (props.data.material_type === 'Archival Collection') {
        const linkStyle = {color: "rgb(135, 8, 94)"}
        return (
          <div><a style={linkStyle} target="_blank" href={props.data.ss_url}>View collection guide for full contents</a></div>
        )
      }
    }
    

    if (callNumber === '' && publisher === '' && language === '' && targetAge === '') {
      return '';
    }

    // <div><IconSparkles /></div>
    
    if (props.data.material_type === 'Archival Collection') {
      //const linkStyle = {color: "rgb(135, 8, 94)"}
      return (
        <div>
          
          <div className="archival-collection-flex-message">
            <div>
              <p>This collection is available on site at the <a target="_blank" href="https://www.bklynlibrary.org/center-for-brooklyn-history">Center for Brooklyn History</a>. Email us at <a href="mailto:cbhreference@bklynlibrary.org">cbhreference@bklynlibrary.org</a> to request access.</p><p>For a full listing of collection contents, reference our <a target="_blank" href={props.data.ss_url}>collection guide</a>.</p>
            </div>
          </div>
          <br></br>
          <h3>Call Number</h3>
          <div>{callNumber}</div>
          
        </div>
      )
    }

    return (
      <div>
        <h3>Details</h3>
        <div className="primary-item-details-container">
          <ArchivesSpaceLink data={props.data} />
          <CallNumber text={callNumber}></CallNumber>
          <Publisher text={publisher}></Publisher>
          <Language text={language}></Language>
          <TargetAge text={targetAge}></TargetAge>
        </div>
      </div>
      
    )
  } catch {}
};

export default PrimaryItemDetails;