import configs from "../../functions/configs";
import fallbackImageHandler from "../../functions/fallbackImageHandler";
import onLoadImageHandler from "../../functions/onLoadImageHandler";
import CaretDown from "../icons/caretDown";
import arrayUnique from "../../functions/arrayUnique";
import GetThisItemButton from "../shared/getThisItemButton";
import AddToListButton from "../shared/addToListButton";
import CatalogFormatIcon from "../icons/catalogFormatIcon";
import returnBestImage from "../../functions/returnBestImage";
import RebalancingClaimButton from "../shared/rebalancingClaimButton";

const SearchResultGroupedCatalog = (props) => {

  const conf = configs();

  const url = new URL(window.location.href);

  const updateSearchData = props.updateSearchData;

  let titleLink = "/item?b=" + props.data[0].id;

  let title = '';
  try {title = props.data[0].title; } catch {}

  let imgSource = returnBestImage(props.data);

  let imageAlt = 'jacket art';
  try {imageAlt = props.data[0].tm_title[0].replace(/[\'\"\<\>]/, '') + ' ' + imageAlt;} catch {};

  let summary = "";
  try {
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].summary[0].length > summary) {
        summary = props.data[i].summary[0];
      }
    }
    summary = summary.substring(0, conf.summaryLength);
    summary.trim();
    if (!summary.match(/\.$/)) {
      summary += '...';
    }
  } catch {}

  const AuthorSpan = (props) => {
    const AuthorLink = (props) => {
      try {
        let href = "/?catalog=true&author=" + encodeURIComponent(props.data);
        return (
          <a className="notranslate" href={href}>{props.data}</a>
        )
      } catch {}
    }
    let author = '';
    try {author = props.data.author;} catch {};
    if (author === '' || typeof author !== 'string') {
      return (<span></span>)
    }
    return (<span>by <AuthorLink data={props.data.author}/></span>)
  }

  const AvailabilityDiv = (props) => {
    let alwaysAvailable = [];
    let availableNow = [];
    let waitlist = [];
    let onOrder = [];
    let libUseOnly = [];
    let other = [];
    for (let i = 0; i < props.data.length; i++) {
    
      if (conf.digitalTypes.indexOf(props.data[i].material_type) > -1) { // digital stuff
        if (props.data[i].eprovider === "OverDrive, Inc.") {
          if (props.data[i].available ===  true) {
            if (props.data[i].digital_avail_type === "AlwaysAvailable") {
              alwaysAvailable.push(props.data[i]);
            } else {
              availableNow.push(props.data[i]);
            }
          } else {
            waitlist.push(props.data[i]);
          }
        } else {
          availableNow.push(props.data[i]);
        }
      } else { // physical stuff
        if (props.data[i].bs_lib_use_only) {
          libUseOnly.push(props.data[i]);
        } else if (props.data[i].bs_on_order) {
          onOrder.push(props.data[i]);
        } else {
          if (props.data[i].available) {
            availableNow.push(props.data[i]);
          } else {
            waitlist.push(props.data[i]);
          }
        }
      }
    }

    const toggleAvailabilitySet = (e) => {
      e.preventDefault();
      try {
        e.currentTarget.parentElement.nextElementSibling.classList.toggle('hide');
        e.currentTarget.nextElementSibling.classList.toggle('flip');
      } catch (e) {console.log(e)}
    }

    const IndividualFormatListing = (props) => {
      let formatLink = "/item?b=" + props.data.id;
      let prettyName = ' ' + props.data.material_type;
      if (props.data.publishYear) {
        prettyName = ' ' + props.data.material_type + " - " + props.data.publishYear;
      } 
      
      return (
        <div  className="grouped-search-format-listing-container">
          <a className="grouped-search-format-listing" href={formatLink}><CatalogFormatIcon format={props.data.material_type} /> {prettyName}</a>
        </div>
      )
    }

    const FormatsMapping = (props) => {
      return (
        props.data.map(format => <IndividualFormatListing key={format.id} data={format}></IndividualFormatListing>)
      )
    }

    const marginBottom = {marginBottom: "8px"};

    const AlwaysAvailable = (props) => {
      if (props.data.length > 0) {
        let length = props.data.length;
        let plural = '';
        if (props.data.length > 1) {
          plural = 's';
        }
        return (
          <div style={marginBottom}>
            <div>
              <span className="color-available">Always Available</span> in <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
            </div>
            <div className="grouped-formats-listing-container hide">
              <FormatsMapping data={props.data}></FormatsMapping>
            </div>
          </div>
        )
      } else {
        return (<div></div>)
      }
    }

    const LibUseOnly = (props) => {
      if (props.data.length > 0) {
        let length = props.data.length;
        let plural = '';
        if (props.data.length > 1) {
          plural = 's';
        }
        return (
          <div style={marginBottom}>
            <div>
              <span className="color-pending">Library Use Only</span> in <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
            </div>
            <div className="grouped-formats-listing-container hide">
              <FormatsMapping data={props.data}></FormatsMapping>
            </div>
          </div>
        )
      } else {
        return (<div></div>)
      }
    }

    const OnOrder = (props) => {
      if (props.data.length > 0) {
        let length = props.data.length;
        let plural = '';
        if (props.data.length > 1) {
          plural = 's';
        }
        return (
          <div style={marginBottom}>
            <div>
              <span className="color-pending">On Order</span> in <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
            </div>
            <div className="grouped-formats-listing-container hide">
              <FormatsMapping data={props.data}></FormatsMapping>
            </div>
          </div>
        )
      } else {
        return (<div></div>)
      }
    }

    const AvailableNow = (props) => {
      if (props.data.length > 0) {
        let length = props.data.length;
        let plural = '';
        if (props.data.length > 1) {
          plural = 's';
        }
        
        try {
          if (props.data[0].material_type === 'Tool') {
            return (
              <div style={marginBottom}>
                <div>
                  <span className="color-available">Place a hold</span> for <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
                </div>
                <div className="grouped-formats-listing-container hide">
                  <FormatsMapping data={props.data}></FormatsMapping>
                </div>
              </div>
            )
          }
        } catch {}
        
        return (
          <div style={marginBottom}>
            <div>
              <span className="color-available">Available Now</span> in <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
            </div>
            <div className="grouped-formats-listing-container hide">
              <FormatsMapping data={props.data}></FormatsMapping>
            </div>
          </div>
        )
      } else {
        return (<div></div>)
      }
    }

    const Waitlist = (props) => {
      if (props.data.length > 0) {
        let length = props.data.length;
        let plural = '';
        if (props.data.length > 1) {
          plural = 's';
        }
        return (
          <div style={marginBottom}>
            <div>
              <span className="color-pending">Wait List</span> for <a className="expand-formats" onClick={toggleAvailabilitySet} aria-label="expand formats" href="#">{length} format type{plural}</a> <CaretDown></CaretDown>
            </div>
            <div className="grouped-formats-listing-container hide">
              <FormatsMapping data={props.data}></FormatsMapping>
            </div>
          </div>
          )
      } else {
        return (<div></div>)
      }
    }

    return (
      <div className="availability-container">
        <AlwaysAvailable data={alwaysAvailable}></AlwaysAvailable>
        <AvailableNow data={availableNow}></AvailableNow>
        <OnOrder data={onOrder}></OnOrder>
        <Waitlist data={waitlist}></Waitlist>
        <LibUseOnly data={libUseOnly}></LibUseOnly>
        
      </div>
    );

  }

  const SubjectPillButtons = (props) => {
    const subjects = [];

    try {
      for (let i = 0; i < props.data.length; i++) {
        if (typeof props.data[i].subjects !== 'undefined') {
            for (let x = 0; x < props.data[i].subjects.length; x++) {
              subjects.push(props.data[i].subjects[x]);
            }
        }
      }
    } catch {}
    
    const subjectsUnique = arrayUnique(subjects);

    subjectsUnique.sort();

    if (subjectsUnique.length > conf.subjectPillLimit) {
      try {subjectsUnique.splice(conf.subjectPillLimit); } catch {}
    }

    return subjectsUnique.map((item) => <SubjectPillButton key={item} name={item} />);
  }

  const SubjectPillButton = (props) => {
    let isActivated = 'no';
    if (url.searchParams.get('subjects') !== null) {
      const values = url.searchParams.get('subjects').split('||');
      if (values.indexOf(props.name) > -1) {
        isActivated = 'yes';
      }
    }
    return (<button onClick={updateSearchData} filtertype='catalog-tag' filterval={props.name} activated={isActivated} className="subject-pill-btn no-color">{props.name}</button>);
  }

  const CallNumber = (props) => {
    try {

      const marginBottom = {marginBottom: '8px'};

      for (let i = 0; i < props.data.length; i++) {
        if (props.data[i].material_type === 'Book') {
          if (props.data[i].call_number === '' || props.data[i].call_number === null) {
            return ''
          }
          return (
            <div style={marginBottom}><span className="bold notranslate">Call Number:</span> {props.data[i].call_number}</div>
          )
        }
      }

    } catch {}
  }

  return (
    <div className="result-container">
      <div className="image-container">
        <a href={titleLink}>
          <img onLoad={onLoadImageHandler} onError={fallbackImageHandler} src={imgSource} alt={imageAlt}></img>
        </a>
      </div>
      <div className="result-detail-container">
        <div className="result-detail-text">
          <div className="result-title">
          <a href={titleLink}>
            {title}
          </a>
          </div>
          <div className="result-author">
            <AuthorSpan data={props.data[0]} />
          </div>
          <div className="result-availability grouped">
            <AvailabilityDiv data={props.data}></AvailabilityDiv>
          </div>
          <div>
            <CallNumber data={props.data}></CallNumber>
          </div>
          <div className="result-summary">{summary}</div>
          <div className="result-detail-actions wrap">

            <GetThisItemButton setSelectedBibId={props.setSelectedBibId} bib={props.data[0].id}></GetThisItemButton>
            <AddToListButton setSelectedBibId={props.setSelectedBibId} bib={props.data[0].id}></AddToListButton>
            <RebalancingClaimButton data={props.data} />
          </div>
          <div className="subject-pill-btn-container"><SubjectPillButtons data={props.data}></SubjectPillButtons></div>
        </div>
      </div>
    </div>
  )
    
};

export default SearchResultGroupedCatalog;
