import configs from "../../functions/configs";
import getData from "../../functions/getData";
import parseVarfields from "../../functions/parseVarfields";
import { useState } from "react";

const MetadataSection = (props) => {

  const [metadataSectionClasses, setMetadataSectionClasses] = useState('');
  const [metadataBtnClasses, setMetadataBtnClasses] = useState('tab-section-toggle active');

  const [reviewsSectionClasses, setReviewsSectionClasses] = useState('hide');
  const [reviewsBtnClasses, setReviewsBtnClasses] = useState('tab-section-toggle');

  const [marcSectionClasses, setMarcSectionClasses] = useState('hide');
  const [marcBtnClasses, setMarcBtnClasses] = useState('tab-section-toggle');

  const [overdriveReviews, setOverdriveReviews] = useState({});

  const marcSubfieldExcludes = ["1","2","3","4","5","6","7","8","9","0"];

  try {
    const marcFields = parseVarfields(props.data.sm_bib_varfields);
    
    marcFields.sort((a, b) => {
      if (a.tag > b.tag) {
        return 1;
      } else if (a.tag < b.tag) {
        return -1
      } else {
        return 0;
      }
    });

    for (let i = 0; i < marcFields.length; i++) {
      marcFields[i].marcCount = i;
    }
    
    const SubjectsPill = (props) => {
      let link = '';

      try {
        link = '/?catalog=true&subjects=' + encodeURIComponent(props.data);
      } catch {}

      return (
        <a href={link} className="subject-pill-btn">{props.data}</a>
      )
    }

    const SubjectsMap = (props) => {
      return props.data.map((item) => <SubjectsPill key={item} data={item}></SubjectsPill>);
    }

    const Tags = (props) => {
      if (typeof props.data.subjects !== 'undefined') {
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Tags</div>
            <div className="subject-pill-btn-container">
              <SubjectsMap data={props.data.subjects}></SubjectsMap>
            </div>
          </div>
        )
      } else {
        return ''
      }
    }

    const ISBN = (props) => {
      try {
        if (typeof props.data.isbn !== 'undefined') {
          let isbnString = '';
  
          for (let i = 0; i < props.data.isbn.length; i++) {
            if (props.data.isbn[i].indexOf('-') === -1) {
              isbnString += props.data.isbn[i] + ', ';
            }
          }

          isbnString = isbnString.replace(/\, $/, '');

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">ISBNs</div>
              {isbnString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Series = (props) => {
      try {
        if (typeof props.data.series !== 'undefined') {
          let contentString = '';
  
          for (let i = 0; i < props.data.series.length; i++) {
            contentString += props.data.series[i] + ' ';
          }

          contentString = contentString.trim();

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Series</div>
              {contentString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Contributors = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '245') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'c') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }

        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Contributor(s)</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const AdditionalContributors = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '700') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'a') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Additional Contributors</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const CorporateName = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '710') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag === 'a') {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Corporate Contributor/Supplier</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const LanguageNotes = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '546') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Language Notes</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const PhysicalDescription = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '300') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Physical Description</div>
            {contentString}
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const Event = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '518') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Event</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const Performer = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '511') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Performer</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    //Creation/Production Credits
    const CreationProductionCredits = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '508') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Creation/Production Credits</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const TargetAudienceMARC = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '521') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (!marcSubfieldExcludes.includes(props.data[i].subfields[x].subfieldTag)) {
                  contentString += props.data[i].subfields[x].subfieldContent + ' ';
                }
            }
          }
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Audience</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const NoteContent = (props) => {
      return (
        <div>{props.data}</div>
      )
    }

    const NotesWithLineBreak = (props) => {
      return props.data.map((item) => <NoteContent key={item} data={item}></NoteContent>)
    }

    const Notes = (props) => {
      try {
        const contentArray = [];
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '500') {
            for (let x = 0; x < props.data[i].subfields.length; x++) {
                if (props.data[i].subfields[x].subfieldTag.match(/[0-9]/) === null) {
                  contentArray.push(props.data[i].subfields[x].subfieldContent);
                }
            }
          }
        }

        if (contentArray.length === 0) {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Notes</div>
            <NotesWithLineBreak data={contentArray} ></NotesWithLineBreak>
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const LinkDisplay = (props) => {
      const style={marginBottom: '8px'}
      return (
        <div style={style}><a target='_blank' href={props.data.href}>{props.data.text}</a></div>
      )
    }

    const LinksMap = (props) => {
      return props.data.map((item) => <LinkDisplay key={item.href} data={item}></LinkDisplay>)
    }

    const Links = (props) => {
      try {
        const contentArray = [];
        
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].tag === '856') {
            const obj = {href: '', text: ''};
            for (let x = 0; x < props.data[i].subfields.length; x++) {
              if (props.data[i].subfields[x].subfieldTag === 'u') {
                obj.href = props.data[i].subfields[x].subfieldContent;
              } else {
                obj.text += props.data[i].subfields[x].subfieldContent;
              }
            }
            if (obj.text === '') {
              obj.text = obj.href;
            }
            contentArray.push(obj);
          }
        }

        if (contentArray.length === 0) {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Related Links</div>
            <LinksMap data={contentArray} ></LinksMap>
          </div>
        )
        
      } catch (e) { console.log(e)}
    }

    const FictionStatus = (props) => {
      try {
        if (typeof props.data.fiction_status !== 'undefined') {

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Fiction Status</div>
              {props.data.fiction_status}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const LexileMeasure = (props) => {
      try {
        if (typeof props.data.is_lexile !== 'undefined') {

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Lexile Measure</div>
              {props.data.is_lexile}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const Contents = (props) => {
      try {
        let contentString = '';
        
        for (let i = 0; i < props.data.tm_bibliographic_contents.length; i++) {
          contentString += props.data.tm_bibliographic_contents[i] + ' ';
        }

        contentString = contentString.trim();

        if (contentString === '') {
          return '';
        }
        
        return (
          <div className="metadata-group-container">
            <div className="metadata-group-label main-color bold">Contents</div>
            {contentString}
          </div>
        )
      } catch {}
    }

    const OverdriveReviews = (props) => {
      try {
        if (overdriveReviews.reviews !== 'undefined') {

          

          const OverdriveReview = (props) => {
            let source = '';
            try {
              if (props.data.source.indexOf('<a') === -1) {
                source = '<br><br>- ' + props.data.source;
              } else {
                source = '<br>' + props.data.source;
              }
            } catch {}

            const reviewText = {__html: props.data.content + source}
            return (
              <div className="overdrive-review-container">
                <div dangerouslySetInnerHTML={reviewText}></div>
                <hr></hr>
              </div>
            )
          }

          const ListOfReviews = () => {
            try {
              return overdriveReviews.reviews.map(item => <OverdriveReview key={item.content} data={item} />)
            } catch {}
          }
          
          try {
            if (overdriveReviews.reviews.length > 0) {
              return (
                <div>
                  <h3>Professional Reviews</h3>
                  <ListOfReviews />
                </div>
              )
            }
          } catch {}
          
        }
      } catch {}
    }

    const Reviews = (props) => {

      
      try {

        const GoodreadsLink = (props) => {
          let goodreadsSrc = 'https://www.goodreads.com/search?utf8=%E2%9C%93&query=' + props.data;
          let imgSrc = '/public/goodreads.png';
          return (
            <div>
              <a target="_blank" className="reviews-link" href={goodreadsSrc}><img alt="Goodreads Logo" src={imgSrc}></img><span className="sr-only">GoodReads</span></a>
            </div>
          )
        }

        const NovelistLink = (props) => {
          try {
            if (props.data.author !== '') {
              let src = 'https://search.ebscohost.com/login.aspx?direct=true&AuthType=cookie,ip,cpid&custid=brooklyn&db=neh&tg=UI&site=novp-live&scope=site&bquery=' + props.data.title + " " + props.data.author;
              let imgSrc = '/public/novelist.png';
              return (
                <div>
                  <a target="_blank" className="reviews-link" href={src}><img alt="Novelist Logo" src={imgSrc}></img><span className="sr-only">Novelist</span></a>
                </div>
              )
            }
          } catch {}
        }

        const BakerAndTaylorLink = (props) => {
          let src = 'http://contentcafe2.btol.com/ContentCafeClient/ContentCafe.aspx?UserID=BPL&Password=BT0059&Options=N&ItemKey=' + props.data;
          let imgSrc = '/public/bakertaylor.jpg';
          return (
            <div>
              <a target="_blank" className="reviews-link" href={src}><img alt="Baker and Taylor logo" src={imgSrc}></img><span className="sr-only">Baker and Taylor</span></a>
            </div>
          )
        }
 
        if (typeof props.data.isbn !== 'undefined') {
          return (
            <div className="metadata-group-container">
              <div className="review-icons-container">
                <GoodreadsLink data={props.data.isbn[0]}></GoodreadsLink>
                <NovelistLink data={props.data} />
                <BakerAndTaylorLink data={props.data.isbn[0]} />
              </div>
              <OverdriveReviews />
            </div>
          )
        } else {
          return ''
        }
      } catch {}
      
    }

    const ElectronicFormats = (props) => {
      try {
        if (typeof props.data.eformat !== 'undefined') {
          let contentString = '';
  
          for (let i = 0; i < props.data.eformat.length; i++) {
            contentString += props.data.eformat[i] + ', ';
          }

          contentString = contentString.replace(/\, $/, '');

          return (
            <div className="metadata-group-container">
              <div className="metadata-group-label main-color bold">Electronic Formats</div>
              {contentString}
            </div>
          )
        } else {
          return ''
        }
      } catch (e) { console.log(e)}
      
    }

    const toggleMetadataSection = (e) => {
      e.preventDefault();      
      if (e.currentTarget.id === 'metadata-section-link') {
        setMetadataSectionClasses('');
        setReviewsSectionClasses('hide');
        setMarcSectionClasses('hide');

        setMetadataBtnClasses('tab-section-toggle active');
        setMarcBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle');
      } else if (e.currentTarget.id === 'marc-record-section-link') {
        setMetadataSectionClasses('hide');
        setReviewsSectionClasses('hide');
        setMarcSectionClasses('');

        setMetadataBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle');
        setMarcBtnClasses('tab-section-toggle active');
      } else if (e.currentTarget.id === 'reviews-section-link') {
        setMetadataBtnClasses('tab-section-toggle');
        setMarcBtnClasses('tab-section-toggle');
        setReviewsBtnClasses('tab-section-toggle active');

        setMetadataSectionClasses('hide');
        setMarcSectionClasses('hide');
        setReviewsSectionClasses('');

        let bestId = '';

        try {
          //console.log('group', props.groupData);
          for (let x = 0; x < props.groupData.response.docs.length; x++) {
            if (typeof props.groupData.response.docs[x].econtrolnumber !== 'undefined') {
              if (props.groupData.response.docs[x].econtrolnumber !== '' && props.groupData.response.docs[x].econtrolnumber !== null) {
                bestId = props.groupData.response.docs[x].econtrolnumber;
              }
              if (props.groupData.response.docs[x].material_type === 'eBook') {
                break;
              }
            }
          }
        } catch {}

        if (bestId === '') {
          return '';
        }

        try { // pull data from OD
          getData(configs().baseAPI + '/overdrive/overdrive-return-reviews.php?od_id=' + bestId).then(res => {
            console.log(res);
            try {
              setOverdriveReviews(res);
            } catch {}
          });
        } catch {}
      }
    }

    const MarcGroup = (props) => {
      let content = '';
      try {
        for (let i = 0; i < props.data.subfields.length; i++) {
          if (typeof props.data.subfields[i].subfieldTag !== 'undefined') {
            content += '(' + props.data.subfields[i].subfieldTag + ') ';
          }
          content += props.data.subfields[i].subfieldContent + ' ';
        }
      } catch (e) {console.log(e)}
      
      return (
        <div className="marc-group">
          <div className="marc-tag">{props.data.tag}:</div>
          <div className="marc-content">{content}</div>
        </div>
      )
    }

    const MarcSection = (props) => {
      return props.data.map((item) => <MarcGroup key={item.marcCount} data={item}></MarcGroup>);
    }

    let reviewsHideOverride = {};
    let marcHideOverride = {};

    try {

      // only show reviews tab for some material types
      if (props.data.material_type !== 'Book' 
        && props.data.material_type !== 'eAudiobook'
        && props.data.material_type !== 'Large Print Book'
        && props.data.material_type !== 'eBook') {
        reviewsHideOverride = {display: "none"};
      }

      if (props.data.material_type === 'Archival Collection') {
        reviewsHideOverride = {display: "none"};
        marcHideOverride = {display: "none"};

        return (
          ''
        )
      }
    } catch {}
    

    return (
      <div className="metadata-section">
        <div className="tab-flex-container">
          <a href="#" onClick={toggleMetadataSection} id="metadata-section-link" className={metadataBtnClasses}>Metadata</a>
          <a href="#" style={reviewsHideOverride} onClick={toggleMetadataSection} id="reviews-section-link" className={reviewsBtnClasses}>Reviews</a>
          <a href="#" style={marcHideOverride} onClick={toggleMetadataSection} id="marc-record-section-link" className={marcBtnClasses}>MARC Record</a>
        </div>
        <div className={metadataSectionClasses} id="metadata-details-container">
          <Tags data={props.data}></Tags>
          
          <ISBN data={props.data}></ISBN>
          <FictionStatus data={props.data}></FictionStatus>
          <Contributors data={marcFields}></Contributors>
          <AdditionalContributors data={marcFields}></AdditionalContributors>
          <Series data={props.data}></Series>
          <CorporateName data={marcFields}></CorporateName>
          <ElectronicFormats data={props.data}></ElectronicFormats>
          <Notes data={marcFields}></Notes>
          <Contents data={props.data} />
          <LexileMeasure data={props.data}/>
          <LanguageNotes data={marcFields}/>
          <PhysicalDescription data={marcFields}/>
          <TargetAudienceMARC data={marcFields}/>
          <Event data={marcFields}/>
          <Performer data={marcFields}/>
          <CreationProductionCredits data={marcFields}/>
          <Links data={marcFields}></Links>
        </div>
        <div className={reviewsSectionClasses} id="reviews-container">
          <Reviews groupData={props.groupData} data={props.data} />
        </div>
        <div className={marcSectionClasses} id="marc-record-container">
          <MarcSection data={marcFields}></MarcSection>
        </div>
      </div>
    )
  } catch {}
};

export default MetadataSection;