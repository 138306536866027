import useFetch from '../../hooks/useFetch';
import ContentCarousel from '../shared/contentCarousel';
import configs from '../../functions/configs';

const HomePageDiscovery = (props) => {

    const conf = configs();
    
    const [booklist1] = useFetch(conf.searchAPI + '?booklist=cbscmocks2025');
    const [booklist2] = useFetch(conf.searchAPI + '?booklist=716805');
    const [booklist3] = useFetch(conf.searchAPI + '?booklist=717902');
    
    //const [summerQuery] = useFetch(conf.searchAPI + '?catalog=true&subjects=Summer&materialtype=Book&sort=publishdate&view=grid');

    const [authorsQuery] = useFetch(conf.selectAPI + '?fq=ss_type%3Acatalog&json.facet=' + encodeURIComponent('{"author" : {"type": "terms","field": "ss_author_autocomplete","limit": 20,"sort": "avg_popularity desc","facet": {"avg_popularity":"max(popularity)"}}}'));

    const PopularAuthors = (props) => {

        let count = 0;

        const Link = (props)=> {
            count ++;
            let href= "/?catalog=true&author=" + props.data;
            try {
                if (props.data !== '' && count < 40) {
                    return (
                        <a className='discovery-pill-link' href={href}>{props.data}</a>
                    )
                }
                
            } catch {}
        }

        try {
            return (
                authorsQuery.facets.author.buckets.map(item=> <Link key={item.val} data={item.val}></Link>)
            )
        } catch {}
    }

    /*
    const TopicCarousel = (props) => {
        try {
            return (
                <div className='discover-carousel-card'>
                    <div style={exploreStyle} className='dark-gray'>EXPLORE</div>
                    <h3>Women's History Month</h3>
                    <ContentCarousel data={womensHistoryQuery.response.docs}></ContentCarousel>
                </div>
            )
        } catch {}
    }
    */

    const BooklistCarousel = (props) => {
        try {
            let title = props.listData.original_list.title.replace(/bklyn bookmatch\:/i, '');
            title = title.trim();
            try {
                title = title.replace('BKLYN Inclusive Services -- ', '');
                if (title === "Adult Holiday Reads - December 2023") {
                    title = 'Holiday Reads';
                }
            } catch {}
            let link = '/?booklist=' + props.listData.original_list.booklist_id;
            const carouselData = props.listData.response.docs;
            return (
                <div className='discover-carousel-card'>
                    <div className='search-carousel-tip-top-container'>
                        <div>
                            <div className='dark-gray bold'>STAFF SELECTIONS</div>
                            <h3 className='bold'>{title}</h3>
                        </div>
                        <div className='search-carousel-tip-link-container'><a href={link}>View All</a></div>
                    </div>
                    <ContentCarousel data={carouselData}/>
                </div>
            )
        } catch {}
    }

    try {

        return (
            <div className='homepage-discovery-container'>
                <h3>Browse Popular Topics</h3>
                <div className='pill-link-discovery-container'>
                    <a className='discovery-pill-link' href="/?catalog=true&pasttwoyears=true">Bestsellers</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&materialtype=DVD&pasttwoyears=true">New DVDs</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&subjects=Romance">Romance</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&subjects=Picture+books">Picture Books</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&targetage=Teens">Popular Teen Reads</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&subjects=Biography">Biographies & Memoirs</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&targetage=Kids&subjects=Chapter+books">Kids Chapter Books</a>
                    <a className='discovery-pill-link' href="/?catalog=true&sort=popularity&subjects=Graphic+novels">Graphic Novels</a>
                    <a className='discovery-pill-link' href="/?booklistfilter=true">New Booklists</a>
                    <a className='discovery-pill-link' href="/?event=true">Events</a>
                </div>

                <BooklistCarousel listData={booklist1}/>
               

                <h3>Browse by Language</h3>
                <div className='pill-link-discovery-container'>
                    <a className='discovery-pill-link' href="/?catalog=true&language=Español+%7C+Spanish">Español | Spanish <span className='dark-gray subtext'>(27k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=русский+%7C+Russian">русский | Russian <span className='dark-gray subtext'>(27k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=中文+%7C+Chinese">中文 | Chinese <span className='dark-gray subtext'>(25k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=français+%7C+French">français | French <span className='dark-gray subtext'>(7k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=Polskie+%7C+Polish">Polskie | Polish <span className='dark-gray subtext'>(6k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=עִברִית+%7C+Hebrew">עִברִית | Hebrew <span className='dark-gray subtext'>(3k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=Deutsch+%7C+German">Deutsch | German <span className='dark-gray subtext'>(3k)</span></a>
                    <a className='discovery-pill-link' href="/?catalog=true&language=Kreyòl+%7C+Haitian+French+Creole">Kreyòl <span className='dark-gray subtext'>(1k)</span></a>
                </div>

                <BooklistCarousel listData={booklist2}/>

                <h3>Browse Popular Authors</h3>
                <div className='pill-link-discovery-container'>
                    <PopularAuthors></PopularAuthors>
                </div>

                <BooklistCarousel listData={booklist3}/>

            </div>
        )

    } catch {}

}

export default HomePageDiscovery;